/*!
 * obebs4-toasts.js
 */

jQuery(document).ready(function($) {
    
    // Initialize Bootstrap Toasts with OBE:BS4 default options
    $('.toast').toast({
        'animation': true,
        'autohide': true,
        'delay': 500,
    });
    
}); // end document ready